import {
	Avatar,
	AvatarGroup,
	Button,
	Card,
	CardContent,
	Chip,
	Grid,
	Stack,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import { cardStyle } from "../../styles/card";
import LiveTag from "../LiveTag/LiveTag";
const humanizeDuration = require("humanize-duration");

export const PlayOffCard = ({
	playoff,
	challengeSection = false,
	firstCard = false,
}) => {
	const router = useRouter();
	const style = cardStyle;
	let voting_end_date = moment(playoff.play_off_end_date_time);
	const [remainingStamp, setRemainingStamp] = useState(null);
	const [isAfter, setIsAfter] = useState(voting_end_date.isAfter(moment()));
	const shortEnglishHumanizer = humanizeDuration.humanizer({
		language: "shortEn",
		units: ["y", "mo", "w", "d", "h", "m"],
		round: true,
		spacer: "",
		delimiter: " ",
		languages: {
			shortEn: {
				y: () => "Y",
				mo: () => "M",
				w: () => "W",
				d: () => "D",
				h: () => "H",
				m: () => "m",
				s: () => "s",
				ms: () => "ms",
			},
		},
	});

	var settings = {
		dots: false,
		arrows: false,
		initialSlide: 0,
		// fade: true ,
		infinite: !challengeSection,
		autoplay: !challengeSection,
		speed: !challengeSection ? 8000 : 0,
		autoplaySpeed: 0,
		slidesToShow: playoff.slider.length >= 4 ? 3.4 : playoff.slider.length,
		slidesToScroll: 1,
		cssEase: "linear",
	};

	useEffect(() => {
		setRemainingStamp(
			`Ends in ${shortEnglishHumanizer(
				moment.duration(voting_end_date.diff(moment()))
			)}`
		);
		const interval = setInterval(() => {
			setRemainingStamp(
				`Ends in ${shortEnglishHumanizer(
					moment.duration(voting_end_date.diff(moment()))
				)}`
			);
			setIsAfter(voting_end_date.isAfter(moment()));
		}, 10000);
		return () => {
			if (voting_end_date.isSameOrBefore(moment())) {
				clearInterval(interval);
			}
			setIsAfter(voting_end_date.isAfter(moment()));
		};
	}, []);

	useEffect(() => {
		// let { grouped_list, groups, products } = playoff;
		// if (grouped_list) {
		// 	let group_products = [];
		// 	group_products = groups.map((group) => group.products);
		// 	group_products = group_products.flat(1);
		// 	setProducts(group_products);
		// } else {
		// 	setProducts(products);
		// }
	}, []);

	return (
		<Card
			sx={{
				...style.card,
				marginBottom: challengeSection || firstCard ? "30px" : 0,
				boxShadow: "none",
				position: "relative",
			}}
		>
			{!challengeSection && playoff.is_playoff && (
				<Box
					sx={{
						top: "22px",
						left: "0",
						padding: "10px",
						position: "absolute",
						zIndex: 1,
						backgroundColor: "background.default",
						borderRadius: "8px 0",
					}}
				>
					<Typography
						variant="body2"
						color={"primary"}
						sx={{
							fontWeight: 700,
							textTransform: "uppercase",
						}}
					>
						Playoff
					</Typography>
				</Box>
			)}
			<Box px={0.5} pt={0.5} sx={{}} onClick={()=>router.push(`/playoff/${playoff.slug}`)}>
				<Slider {...settings} sx={{ borderRadius: 3 }}>
					{playoff.slider?.map((item, i) => {
						return <Item key={i} item={item} />;
					})}
				</Slider>
			</Box>
			<CardContent
				sx={{
					paddingBottom: challengeSection ? "13.7px !important" : 3,
				}}
			>
				<Typography gutterBottom variant="h4" component="div">
					{playoff.title}
				</Typography>
				{!challengeSection && (
					<Stack
						pt={1}
						direction="row"
						spacing={1}
						alignItems="center"
					>
						{/* {voting_end_date.isAfter(moment()) ? ( */}
						<>
							<LiveTag></LiveTag>
							{isAfter && (
								<Typography
									component={"span"}
									variant={"body2"}
									ml={1}
								>
									{remainingStamp}
								</Typography>
							)}
						</>

						{/* : (
								<>
									<Chip
										label="Concluded"
										size="small"
										sx={{
											backgroundColor: "#E7F5FC",
											color: "#1D83AE",
										}}
									/>
								</>
							)} */}
					</Stack>
				)}
				<Stack
					onClick={() => {
						router.push(`/playoff/${playoff.slug}/curations`);
					}}
					pt={1}
					direction="row"
					spacing={1}
					alignItems="center"
				>
					<AvatarGroup
						max={3}
						sx={{
							flexDirection: "row",
							"& .MuiAvatar-root": {
								fontSize: 15,
							},
							"& .MuiAvatar-root:last-child": {
								marginLeft: "-8px",
							},
							"& .MuiAvatar-root:first-of-type": {
								marginLeft: "0px",
							},
						}}
					>
						{playoff.curations.map((curation, i) => {
							return (
								<Avatar
									key={`curation-${i}`}
									sx={{ width: 24, height: 24 }}
								>
									<Image
										src={curation.image?.file}
										alt={curation.title}
										width={24}
										height={24}
									/>
								</Avatar>
							);
						})}
					</AvatarGroup>
					<Grid item>
						<Typography variant={"body2"}>
							{`${playoff.curations_count} curations from the community`}
						</Typography>
					</Grid>
				</Stack>

				<Stack pt={1} direction="row" spacing={1} alignItems="center">
					<Button
						fullWidth
						disableElevation
						onClick={() => {
							localStorage.removeItem("playoff_id");
							localStorage.removeItem("products");
							router.push(`/playoff/${playoff.slug}`);
						}}
						// href={`/playoff/${playoff.id}`}
						variant={challengeSection ? "outlined" : "contained"}
						color="primary"
					>
						Curate {challengeSection ? "" : " an Outfit"} Now
					</Button>
				</Stack>
			</CardContent>
		</Card>
	);
};

function Item({ item }) {
	return (
		<Image
			src={item.file}
			variant="square"
			alt="slider"
			width={95.33}
			height={137}
			loading="lazy"
		></Image>
	);
}
