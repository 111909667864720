import { Box } from "@mui/material";
import React from "react";

import Lottie from "react-lottie";
import animationData from "../../assets/animation/live-badge.json";
function LiveTag() {
	const options = {
		animationData: animationData,
		loop: true,
		style: {
			height: "16px",
			width: "42px",
		},
	};
	// const { View } = useLottie(options);
	return (
		<Box
			sx={{
				borderRadius: "10px",
				overflow: "hidden",
			}}
		>
			<Lottie options={options} height={16} width={42}></Lottie>
		</Box>
	);
}

export default LiveTag;
