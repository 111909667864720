import {
	Fragment,
	useCallback,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";

import { PaginationLoader } from "../LoadingSpinner/PaginationLoader";
import NoCuration from "../Playoff/Index/NoCuration";
import { EmptyContainer } from "./EmptyContainer";

import { Box, Stack } from "@mui/system";
import { ScrollContext } from "../../context/ScrollContext";

export const ScrollContainer = ({
	items,
	emptyText,
	setItems,
	children,
	APICallFunc,
	id = null,
	page = 1,
	noCuration = false,
	session = null,
	shimmertype = "curation",
	mX = 0,
	isListingScreen = false,

	playoff = null,
}) => {
	const [pageNo, setPageNo] = useState(page);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const elementRef = useRef(null);
	const [firstLoad, setFirstLoad] = useState(true);

	const {
		updatePaginationItems,
		scrollContainerRef,
		pageDetail,
		setPageDetail,
	} = useContext(ScrollContext);

	const onIntersection = useCallback(
		(entries) => {
			const firstEntry = entries[0];
			if (firstEntry.isIntersecting && hasMore && !loading) {
				fetchMoreItems();
				// observerRef.current.unobserve(firstEntry.target);
			}
		},
		[hasMore, loading, firstLoad]
	);

	useEffect(() => {
		const observer = new IntersectionObserver(onIntersection);
		if (observer && elementRef.current) {
			observer.observe(elementRef.current);
		}

		return () => {
			if (observer && elementRef.current) {
				// observer.unobserve(elementRef.current);
				observer.disconnect();
			}
		};
	}, [onIntersection]);

	async function fetchMoreItems() {
		setLoading(hasMore);
		if (firstLoad && pageDetail && pageDetail.items.length) {
			setItems(pageDetail.items);
			if (
				pageDetail.items.length &&
				pageDetail.itemCount > 0 &&
				pageDetail.items.length < pageDetail.itemCount
			) {
				setPageNo(pageDetail.page);
				if (pageDetail.items.length == pageDetail.itemCount) {
					setHasMore(false);
				}
			} else {
				setHasMore(false);
			}
			setLoading(false);
			setTimeout(() => {
				if (window.scrollY < pageDetail.scrollTo) {
					window.scrollTo({
						top: pageDetail.scrollTo,
						behavior: "smooth",
					});
				}
			}, 100);
			if (pageDetail.items.length == pageDetail.itemCount) {
				setHasMore(false);
			}

			// setPageDetail(null);
		} else {
			const { data } =
				id === null
					? await APICallFunc(session, pageNo)
					: await APICallFunc(session, id, pageNo);
			const { next } = data;
			let curations = data.results;
			// if (curationId) {
			// 	curations = curations.filter(({ id }) => id !== curationId);
			// }

			setItems([...items, ...curations]);
			updatePaginationItems(
				[...items, ...curations],
				pageNo + 1,
				data.count
			);
			if (next !== null) {
				setPageNo(pageNo + 1);
			}
			setLoading(false);
			setHasMore(next !== null);
		}

		// if (elementRef.current) {
		// 	if (pageNo != 1) {
		// 		elementRef.current.scrollIntoView({ behavior: "auto" });
		// 	}
		// }
		setFirstLoad(false);
	}
	return (
		<Box>
			{children}

			<div ref={elementRef}>
				{loading && (
					<PaginationLoader
						key={"loaderrr-1"}
						shimmertype={shimmertype}
						pageNumber={pageNo}
						mX={mX}
						isListingScreen={isListingScreen}
					></PaginationLoader>
				)}
			</div>
			{!firstLoad &&
				items.length <= 0 &&
				(noCuration == true ? (
					<NoCuration id={id} slug={playoff}></NoCuration>
				) : (
					<EmptyContainer title={emptyText} />
				))}
		</Box>
	);
};
