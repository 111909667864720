import { Avatar, Box, Button, Typography } from "@mui/material";
import React from "react";

import noCurationImage from "../../../assets/images/no_curation_image.svg";
import { useRouter } from "next/router";

function NoCuration({id,slug}) {
  const router = useRouter();
  
  const clickHandler = () => {
    // navigate("/playoff");
  };
  return (
    <Box sx={{ textAlign: "center", mt: 8, mb: 4 }}>
      <img
        style={{
          width: 126.63,
          height: 150.42,
          objectFit: "contain",
        }}
        alt="No Curation"
        src={noCurationImage}
      ></img>
      <Typography variant="h4">No Curations</Typography>
      <Typography variant="body2" sx={{ opacity: 0.4 }}>
        {/* Please check back later */}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        sx={{ borderRadius: 2, height: "40px", lineHeight: 0, mt: 2 }}
        disableElevation
        onClick={()=>{
          let playoffId=slug?slug:id
          router.push(`/playoff/${playoffId}/products`)
        }}

      >
        Curate Now
      </Button>
    </Box>
  );
}

export default NoCuration;
